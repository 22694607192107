/* You can add global styles to this file, and also import other style files */

$PrimaryColor: #093071;
$accentColor : #c61791;



img.txt-align-img-centered{
    vertical-align: middle;
}

img.txt-align-img-bottom{
    vertical-align: text-bottom;  
}

.container-header-3demak{
    padding-left: 20px;
}

@media screen and (max-width: 600px) {
    .container-header-3demak{
        display:none;
    }
}

.img-header-3demak{
    height: auto;
    width: 80px;
    padding-left: 7px;
}

.txt-header-3demak{
    font-size: 12px;
}
.footer-3demak{
    position: absolute;
    bottom: 0;
    width: 100%;
} 

.footer-logo-3demak{
    background-color: #fff;
    text-align: right;
    padding-right: 20px;
    font-size: 11px;
}

.img-footer-3demak{
    height: auto;
    width: 70px;
}

.container-sponsor{
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.img-sponsor-mp{
    height: 66px;
    padding-right: 5px;
}

.img-sponsor-sec{
    height: 60px;
}

.img-sponsor-qro{
    height: 64px;
    padding-right: 5px;
}

.container-legend-sponsor{
    // width: 50%;
    width: 24%;
    position: absolute;
    right: 0px;
    bottom: 0px;
    text-align: left;
    font-size: 12px;
    background-color: white;
}

@media screen and (max-width: 1300px) {
    .container-legend-sponsor{
        width: 34%;
    }
}

@media screen and (max-width: 940px) {
    .container-legend-sponsor{
        width: 38%;
    }
}

@media screen and (max-width: 840px) {
    .container-legend-sponsor{
        width: 45%;
    }
}

@media screen and (max-width: 620px) {
    .container-legend-sponsor{
        width: 51%;
    }
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.card-container{
    padding: 1em;
    padding-top: calc(108px + 1em);
    .mat-card{
        box-shadow: none;
    }
    .mat-card-header-text{
        margin: 0;
    }
}
.flat{border-radius: 0em !important;}

.blur{
    filter: blur(1px);
}
.table-container{
    position: relative;
    min-height: 10em;
    .spinner{
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(64,64,64,0.3);
        .mat-progress-bar{
            height: 10px;
            left: calc(50% - 25%);
            top: calc(50% - 5px);
            width: 50%;
        }
        .mat-spinner{
            left: calc(50% - 50px);
            top: calc(50% - 50px);
        }
        &+*{
            //filter: blur(1px);
        }
    }

}
.table-container-2{

    position: relative;
    .spinner{
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(64,64,64,0.3);
        
        .mat-spinner{
            left: calc(50% - 50px);
            top: calc(50% - 50px);
        }
        &+*{
            //filter: blur(1px);
        }
    }

}
table {
  width: 100%;
  button.mat-mini-fab{
      box-shadow: none !important;
  }
}
//Theming colors items
button,a{
    &.mat-flat-button,&.mat-mini-fab,&.mat-fab{
        &.mat-accent{
            background-color: $accentColor;
        }
        &.mat-primary{
            background-color: $PrimaryColor;
        }
    }
    &.mat-icon-button{
        &.mat-accent{
            .mat-icon{
                color: $accentColor;
            }
            
        }
        &.mat-primary{
            .mat-icon{
                color: $PrimaryColor;
            }
        }
    }
    &.mat-mini-fab{
        box-shadow: none !important;
    }
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle{
    stroke: $accentColor;
}
.mat-icon{
    &.mat-primary{
        color: $PrimaryColor;
    }
    &.mat-accent{
        color: $accentColor;
    }
}


.bt{
    display: inline-block;
    padding-right: 0.5em;
    &.col-md-12{
        width: calc(100% - 0.5em);
    }
    &.col-md-11{
        width: calc(calc(calc(100% / 12) * 11) - 0.5em);
    }
    &.col-md-10{
        width: calc(calc(calc(100% / 12) * 10) - 0.5em);
    }
    &.col-md-9{
        width: calc(calc(calc(100% / 12) * 9) - 0.5em);
    }
    &.col-md-8{
        width: calc(calc(calc(100% / 12) * 8) - 0.5em);
    }
    &.col-md-7{
        width: calc(calc(calc(100% / 12) * 7) - 0.5em);
    }
    &.col-md-6{
        width: calc(calc(calc(100% / 12) * 6) - 0.5em);
    }
    &.col-md-5{
        width: calc(calc(calc(100% / 12) * 5) - 0.5em);
    }
    &.col-md-4{
        width: calc(calc(calc(100% / 12) * 4) - 0.5em);
    }
    &.col-md-3{
        width: calc(calc(calc(100% / 12) * 3) - 0.5em);
    }
    &.col-md-2{
        width: calc(calc(calc(100% / 12) * 2) - 0.5em);
    }
    &.col-md-1{
        width: calc(calc(calc(100% / 12) * 1) - 0.5em);
    }
}
.text-align-center{
    text-align: center !important;
}
.text-align-right{
    text-align: right;
}
.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}
.hide{
    display: none !important;
}
.float-left{float: left;}
h3{
    text-transform: uppercase;
    font-weight: 700;
}
button.mat-flat-button.mat-primary{
    background-color: $PrimaryColor;
}
h1.mat-dialog-title{
    position:relative;
    text-align: center;
    margin-left: -1em;
    padding-left: 1em;
    margin-right: -1em;
    padding-right: 1em;
    margin-top: -1em;
    padding-top: 1em;
    padding-bottom: 1em;
    background: #ededed;
    .mat-icon{
        float: left;
        position: absolute;
        bottom: -15px;
        left: calc(50% - 12px);
        transform: scale(1.5);
        background: #ffffff;
        border-radius: 50%;
        padding:0.1em;
    }
}
.mat-dialog-content{
    h1{
        font-weight: lighter;
    }
    h1,p{
        text-align: center;
    }
}
.fog-background{
    background-color: whitesmoke;
}
.padding-1em{
    padding: 1em;
}
.no-padding{
    padding: 0 !important;
}

.cdk-column-position{
    padding-left: 0px !important;
    min-width: 30px !important;
}

.body-error-template { 
    height: -webkit-fill-available;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);
}
.error-template {
    padding: 40px 15px;
    text-align: center;
}
.error-actions {
    margin-top:15px;
    margin-bottom:15px;
}
.error-actions .btn {
    margin-right:10px; 
}
.input-full-w {
  width: 100%;
}

.text-justificado{
  text-align: justify;
  text-justify: inter-word;
}

.mat-elevation-z8 {    
  overflow-x: auto;
 }

 .mat-header-cell{
  padding-right: 1.5rem !important;
}



.bg-ft{
    background-color: gainsboro;
}

.modal-backdrop {
    --bs-backdrop-zindex: 300;
}


.mat-header-cell {
    padding-right: 0rem !important;
}

.mat-elevation-z8 {    //agrege esta
    overflow-x: auto;
}
  
.mat-header-cell{ //nueva agregada
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.mat-cell{ //nueva agregada
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
  





th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 0px !important;
}

